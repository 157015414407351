<template>
  <v-container class="mt-6">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="data.length > 0 ? $t('pageDescriptions.am') : ''"
    />

    <template v-if="!fetchingData">
      <DataTable
        v-if="data.length > 0"
        :headers="headers"
        :items="data"
        :preloader="fetchingData"
        :watch="true"
        :trash="false"
        @tableEvent="emittedTableEvent"
      ></DataTable>

      <MarketingFallback v-else />
    </template>

    <DataTable v-else :preloader="fetchingData"></DataTable>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import PageHeader from "@/components/ui/PageHeader";
import DataTable from "@/components/ui/DataTable";
import MarketingFallback from "@/components/addons/annual-marketing/MarketingFallback";

export default {
  name: "AnnualMarketing",

  components: {
    PageHeader,
    DataTable,
    MarketingFallback,
  },

  data() {
    return {
      pageTitle: this.$t("annual_marketing.title"),
      pageDescription: this.$t("annual_marketing.description"),
      fetchingData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      data: [], // wrapper for fetched data
      headers: [],
    };
  },

  props: [],

  created: function () {
    // allow to change company?
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    emittedTableEvent(obj) {
      if (obj.action === "watchItem") {
        this.$router.push({
          name: "annual-marketing:id",
          params: { id: obj.itemId },
        });
      }
    },

    async getData() {
      /* fetch data from api */
      this.fetchingData = true;
      let response = await this.getRequest(
        "companies/" + this.companyID + "/addons/annualMarketing/plans"
      ); // await data from mixin

      this.headers = [
        { text: this.$t("season"), value: "planTitle" },
        { text: this.$t("creationDate"), value: "created" },
        { text: this.$t("updatedate"), value: "updated" },
        { text: "", value: "actions", sortable: false },
      ];

      this.data = response.data.data;

      this.fetchingData = false;
    },
  },

  computed: {
    //
  },

  /* all requests by mixin */
  mixins: [manageApi],
};
</script>
